import { FormGroup } from '@angular/forms';

export enum TabType {
  Lejos = 'Lejos',
  Cerca = 'Cerca',
  Bifocal = 'Bifocal',
  Multifocal = 'Multifocal',
}

export interface Tab {
  label: string;
  content: string;
  active?: boolean;
  canDelete: boolean;
  form: FormGroup;
}
