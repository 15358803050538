import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
@Injectable({
  providedIn: 'root',
})
export class DateFormaterService {
  dateFormater(type: number, date: string | undefined | null): string {
    const dateFormater = DateTime.fromISO(date || '');
    switch (type) {
      case 1:
        return dateFormater.toFormat('dd-MM-yyyy HH:mm');
      case 2:
        return dateFormater.toFormat('dd-MM-yyyy');
      default:
        return ''; // Agregar una declaración de retorno predeterminada
    }
  }
}
