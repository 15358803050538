import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NavbarComponent } from './components/navbar/navbar.component';
import { MainComponent } from './components/main/main.component';
import { PrimaryButtonComponent } from './components/button/primary-button.component';
import { ContainerComponent } from './components/container/container.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { OrderStateNavComponent } from './components/order-state-nav/order-state-nav.component';
import { ModalComponent } from './components/modal/modal.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { PrintFormatComponent } from './components/print-format/print-format.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';

@NgModule({
  declarations: [
    AccordionComponent,
    CheckboxComponent,
    ContainerComponent,
    DatePickerComponent,
    NavbarComponent,
    MainComponent,
    ModalComponent,
    OrderStateNavComponent,
    PrimaryButtonComponent,
    PrintFormatComponent,
    SidebarComponent,
    SpinnerComponent,
    TabsComponent,
  ],
  imports: [CommonModule, HttpClientModule, ReactiveFormsModule, RouterModule],
  exports: [
    AccordionComponent,
    CheckboxComponent,
    ContainerComponent,
    DatePickerComponent,
    NavbarComponent,
    MainComponent,
    ModalComponent,
    OrderStateNavComponent,
    PrintFormatComponent,
    PrimaryButtonComponent,
    SidebarComponent,
    SpinnerComponent,
    TabsComponent,
  ],
  providers: [],
})
export class SharedModule {}
