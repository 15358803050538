<div class="flex justify-center items-center">
  <input
    type="checkbox"
    [formControlName]="formControlName"
    id="{{ id }}"
    name="{{ name }}"
    class="form-checkbox h-5 w-5 text-xblue focus:ring-0 focus-border-0 cursor-pointer duration-300"
  />
  <label for="{{ id }}" class="ml-2 text-xs">{{ label }}</label>
</div>
