/* eslint-disable prefer-const */
import {
  Component,
  effect,
  Injector,
  OnInit,
  runInInjectionContext,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import * as XLSX from 'xlsx';
import { DateTime } from 'luxon';

import { Auth0ManagementService } from '../../shared/services/auth0/auth0-management.service';

import { ClientB2bService } from 'src/app/shared/services/xtension-api-dynamo/client-b2b.service';
import { DateFormaterService } from 'src/app/shared/services/formater-services/date-formater.service';
import { OperativeService } from 'src/app/shared/services/xtension-api-dynamo/operatives.service';

import { TenantService } from 'src/app/shared/services/persistency/tenant.service';
import { WorkorderService } from 'src/app/shared/services/xtension-api-dynamo/workorder.service';

import { FrontendWorkOrder } from 'src/app/shared/types/order/V2/frontend-workorder';
import { ClientB2B } from 'src/app/shared/types/order/V1/clientB2B';
import { Operative } from 'src/app/shared/types/order/V1/operative';

@Component({
  selector: 'x-reports',
  templateUrl: './reports.components.html',
  standalone: false,
})
export class ReportsComponent implements OnInit {
  form!: FormGroup;

  PK = '';
  //Variables Óptica
  clientB2B: ClientB2B | undefined;
  clientB2B_SK: ClientB2B['SK'] = '';

  //Variables Sucursal
  operativos: Operative[] = [] as Operative[];

  //Variables Ordenes
  workOrders: FrontendWorkOrder[] = [] as FrontendWorkOrder[];
  //Variables para ordenar tabla
  isAscending = true;

  exportData: unknown[] = [];

  today: DateTime = DateTime.local();
  formatToday = this.today.toFormat('dd-MM-yyyy - hh:mm a');

  constructor(
    public auth0ManagementSvC: Auth0ManagementService,
    private clientB2BSvC: ClientB2bService,
    private formaterSvC: DateFormaterService,
    private fb: FormBuilder,
    private injector: Injector,
    private tenantSvC: TenantService,
    private operativeSvC: OperativeService,
    private workOrderSvC: WorkorderService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      operative: [''],
    });
    // Uso correcto de effect dentro de un contexto de inyección
    this.tenantSvC.loadTenantData();

    runInInjectionContext(this.injector, () => {
      effect(() => {
        const tenantData = this.tenantSvC.tenant_data();
        if (tenantData) {
          this.PK = tenantData.pk;
          this.getOptic(this.PK);
          this.getOperatives(this.PK);
          this.getOrdersByOperatives(this.PK);
        }
      });
    });
  }

  get operativeSelected(): string {
    return this.form.get('operative')?.value;
  }

  /** Obtener óptica */
  getOptic(PK: string) {
    this.clientB2BSvC.getClientB2B('clientB2B', PK).subscribe((res) => {
      this.clientB2B = res;
    });
  }

  getOperatives(PK: string) {
    this.operativeSvC.getOperatives(`${PK}_workorder`).subscribe({
      next: (res) => {
        this.operativos = res;

        this.form.get('operative')?.setValue(this.operativos[0]);
      },
      error: (error) => {
        return error;
      },
    });
  }

  /** Obtención de ordenes por sucursal seleccionada */
  getOrdersByOperatives(PK: string) {
    this.form.get('operative')?.valueChanges.subscribe((data) => {
      if (data !== '') {
        this.workOrderSvC.getOrdersByOperative(PK, data).subscribe({
          next: (res) => {
            this.workOrders = res.map((item) => item);
            //Exportar datos
            this.exportData = this.workOrders.map((order) => ({
              'Orden Xtension': order.SK,
              'Fecha de ingreso': this.formaterSvC.dateFormater(
                1,
                order.fecha_creacion
              ),
              Sucursal: order.header.sucursal.data.nombre ?? '',
              'Orden Interna': order.header.orden_interna,
              Operativo: order.header.operativo,
              'Tecnólogo/a': order.header.prescriptor.data?.nombre
                ? order.header.prescriptor.data.nombre
                : 'Sin asignar',
              Cliente: order.header.clienteB2C?.data?.nombre,
              'Rut Cliente': order.header.clienteB2C?.data?.rut,
              'Requiere lentes': order.header.prescriptor.data?.requiere_lentes,
              Derivación: order.header.prescriptor.data?.derivacion,
              'Indicaciones Derivación':
                order.header.prescriptor.data?.nombre_derivacion,
            }));
          },
          error: (error) => {
            return error;
          },
        });
      }
    });
  }

  exportToExcel(): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.exportData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `Informe Operativo ${this.formatToday}.xlsx`);
  }
}
