<div class="mb-4 mt-4">
  <div
    class="inline-flex -mb-px text-sm font-medium text-center w-full border-b border-gray-200"
  >
    <div
      *ngFor="let tab of tabs; let i = index"
      class="tab"
      [class.active]="i === eyeglassesConfigService.activeTabIndex"
      (click)="selectTab(i)"
    >
      {{ tab.label }}
      <!-- Mostrar la 'X' solo si la pestaña no es predefinida o puede ser eliminada -->
      <span class="delete-icon" *ngIf="tab.canDelete" (click)="deleteTab(i)">
        x
      </span>
    </div>
    <div
      class="tab-add flex justify-center items-center border-l border-gray-200"
      *ngIf="tabs.length < 4"
    >
      <button
        class="add-button text-xgreen w-full h-full"
        (click)="openAddMenu()"
      >
        +
      </button>
      <div
        class="add-menu duration-300 bg-white border border-xlines"
        *ngIf="eyeglassesConfigService.addMenuOpen"
      >
        <div class="add-option p-2" *ngFor="let tabType of tabTypes">
          {{ tabType }}
        </div>
      </div>
    </div>
  </div>

  <div class="p-4 rounded-lg bg-gray-50 w-full">
    <ng-container *ngIf="tabs.length > 0; else noTabs">
      <div
        [hidden]="eyeglassesConfigService.activeTabIndex !== i"
        *ngFor="let tab of tabs; let i = index"
      >
        {{ tab.form.controls[0] }}
      </div>
    </ng-container>
    <ng-template #noTabs>No tabs available.</ng-template>
  </div>
</div>
