import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Auth0ManagementService } from './shared/services/auth0/auth0-management.service';
import { UserRoleAuth0 } from './shared/services/auth0/types/user-role';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: false,
})
export class AppComponent implements OnInit {
  title = 'xtension';

  user_role: UserRoleAuth0 = {} as UserRoleAuth0;
  constructor(
    public auth0: AuthService,
    public auth0Management: Auth0ManagementService
  ) {}

  ngOnInit(): void {
    this.auth0.user$.subscribe((user) => {
      this.auth0Management
        .getUserRoles(user?.sub)
        .subscribe((user_role: UserRoleAuth0[]) => {
          this.user_role = user_role[0];
        });
    });
  }
}
