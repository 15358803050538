import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { DateFormaterService } from 'src/app/shared/services/formater-services/date-formater.service';
import { OrderNumberFormaterService } from 'src/app/shared/services/formater-services/order-number-formater.service';
import { PrintPDFService } from 'src/app/shared/services/print-pdf/print-pdf.service';
import { WorkorderService } from 'src/app/shared/services/xtension-api-dynamo/workorder.service';

import * as XLSX from 'xlsx';
import { DateTime } from 'luxon';
import { ModalType } from 'src/app/shared/components/modal/types/modal-component';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { TabConfigService } from 'src/app/orders/pages/add-order/components/eyeglasses-form/tabs/services/tabs-config.service';
import { FrontendWorkOrder } from 'src/app/shared/types/order/V2/frontend-workorder';
import { Sucursal } from 'src/app/shared/types/order/V2/entidades/sucursal';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  standalone: false,
})
export class InboxComponent implements OnInit {
  //Variables Sucursal
  branches: Sucursal[] = [];
  branch: Sucursal = {} as Sucursal;

  //Variables Ordenes
  workOrders: FrontendWorkOrder[] = [];
  showSingleOrder = false;

  ordersData: {
    [key: string]: {
      [key: number]: {
        OD_sph: string;
        OI_sph: string;
        OD_cyl: string;
        OI_cyl: string;
        OD_add: number;
        OI_add: number;
      };
    };
  } = {};

  //Filter form
  filterForm: FormGroup = {} as FormGroup;

  //State variables
  states = [
    { public_name: 'Enviado', state_name: 'Enviado' },
    { public_name: 'En Odoo', state_name: 'Confirmado' },
    { public_name: 'Realizado', state_name: 'Realizado' },
  ];

  //Variables para ordenar tabla
  isAscending = true;

  //Seleccionar todas las órdenes
  selectedOrders = new Set<FrontendWorkOrder>();
  //Seleccionar una orden
  selectAllFlag = false;

  //Variables para exportar a excel
  exportData: unknown[] = [];

  //Variables paginador
  pageSize = 10; // Define la cantidad de elementos por página.
  currentPage = 1; // Inicializa la página actual.
  recordsPerPage = 10;

  //Variables para el modal
  modalOpen = false;
  mensajeModal = '';
  botonesModal: string[] = [];
  modalType: ModalType['type'];
  viewOrder: FrontendWorkOrder = {} as FrontendWorkOrder;
  odoo_order_number = 0;
  //Variable que almacena las ordenes originales para aplicar filtros sobre ellas
  originalWorkOrders!: FrontendWorkOrder[];

  //Variables para fecha actual
  today: DateTime = DateTime.local();

  //Obtener vistas de formularios hijos
  @ViewChild(ModalComponent)
  modalComponent!: ModalComponent;

  constructor(
    public dateSvC: DateFormaterService,
    private eyeglassesSvC: TabConfigService,
    private fb: FormBuilder,
    public formaterSvC: OrderNumberFormaterService,
    private printSvC: PrintPDFService,
    private workOrderSvC: WorkorderService
  ) {}

  ngOnInit(): void {
    //Inicialización del formulario para los filtros
    this.createFilterForm();

    //Obtener todas las ordenes
    this.getAllOrders();

    //Obtener los cambios de los formControls
    this.filterForm.valueChanges.subscribe(() => {
      this.filterOrders();
    });
  }

  /** Inicialización de formulario para filtros */
  createFilterForm() {
    this.filterForm = this.fb.group({
      numero_ot: [''],
      ot_odoo: [null],
      nombre_cliente: [''],
      rut_cliente: [''],
      estado: ['Todos los estados'],
    });
  }

  /** Obtener todas las ordenes */
  getAllOrders() {
    this.workOrderSvC.getAllOrdersFromAllTenants().subscribe({
      next: (res) => {
        this.workOrders = res;
        console.log(this.workOrders);

        //Formatear valores rightEye y leftEye
        this.formattedSignValues();
        this.originalWorkOrders = [...this.workOrders];
      },
      error: (error) => {
        return error;
      },
    });
  }
  /**Formatear valores rightEye y leftEye con signos positivos y negativos con Intl.NumberFormat */
  formattedSignValues() {
    this.workOrders.forEach((order) => {
      const prescription = order.body.detalle_prescripcion.prescripcion;
      prescription?.forEach((element, index) => {
        const rightEye = element.rightEye;
        const leftEye = element.leftEye;

        // Verificar si ya existe una entrada para order.SK ?? '' y index, si no, crearla
        if (!this.ordersData[order.SK ?? '']) {
          this.ordersData[order.SK ?? ''] = {};
        }

        // Crear un objeto para almacenar las variables
        const prescriptionData = {
          OD_sph: '',
          OI_sph: '',
          OD_cyl: '',
          OI_cyl: '',
          OD_add: 0,
          OI_add: 0,
        };

        prescriptionData.OD_sph = new Intl.NumberFormat('es-CL', {
          minimumFractionDigits: 2,
          signDisplay: 'exceptZero',
        }).format(rightEye.sph ?? 0);
        prescriptionData.OI_sph = new Intl.NumberFormat('es-CL', {
          minimumFractionDigits: 2,
          signDisplay: 'exceptZero',
        }).format(leftEye.sph ?? 0);

        prescriptionData.OD_cyl = new Intl.NumberFormat('es-CL', {
          minimumFractionDigits: 2,
          signDisplay: 'exceptZero',
        }).format(rightEye.cyl ?? 0);
        prescriptionData.OI_cyl = new Intl.NumberFormat('es-CL', {
          minimumFractionDigits: 2,
          signDisplay: 'exceptZero',
        }).format(leftEye.cyl ?? 0);

        prescriptionData.OD_add = prescription[index].rightEye.add ?? 0;
        prescriptionData.OI_add = prescription[index].leftEye.add ?? 0;

        // Agregar el objeto de datos de prescripción a this.ordersData[order.SK]
        this.ordersData[order.SK ?? ''][index] = prescriptionData;
      });
    });
  }

  getLensesValue(
    index: number,
    lensType: string,
    material: string,
    treatment: string
  ) {
    return this.eyeglassesSvC.getLensesValue(
      index,
      lensType,
      material,
      treatment
    );
  }
  // ---- Filtros ---- /

  filterOrders() {
    let filteredOrders: FrontendWorkOrder[] = this.originalWorkOrders;

    const ot_number = this.filterForm.controls['numero_ot'].value
      .toLowerCase()
      .trim();
    const ot_odoo = this.filterForm.controls['ot_odoo'].value;
    const rutCliente = this.filterForm.controls['rut_cliente'].value
      .toLowerCase()
      .trim();
    const nombreCliente = this.filterForm.controls['nombre_cliente'].value
      .toLowerCase()
      .trim();
    const estado = this.filterForm.controls['estado'].value;

    //Filtrar por ot xtension
    if (ot_number !== '') {
      filteredOrders = filteredOrders.filter((order) =>
        order.SK?.toLowerCase().includes(ot_number)
      );
    }

    //Filtrar por ot odoo
    if (ot_odoo !== null) {
      const ot_odooString = ot_odoo.toString(); // Convertir a cadena de texto
      filteredOrders = filteredOrders.filter((order) =>
        order.odoo_id?.toString().includes(ot_odooString)
      );
    }

    // Filtrar por nombre del cliente
    if (nombreCliente !== '') {
      filteredOrders = filteredOrders.filter((order) =>
        order.header.clienteB2B.data.nombre_fantasia
          .toLowerCase()
          .includes(nombreCliente)
      );
    }

    // Filtrar por rut del cliente
    if (rutCliente !== '') {
      filteredOrders = filteredOrders.filter((order) =>
        order.header.clienteB2B.data.rut.toLowerCase().includes(rutCliente)
      );
    }

    // Filtrar por estado
    if (estado !== 'Todos los estados') {
      filteredOrders = filteredOrders.filter((order) => order.estado == estado);
    }

    this.workOrders = filteredOrders;
  }

  /** Resetear filtros */
  resetFilters() {
    this.getAllOrders();
    this.filterForm.controls['numero_ot'].reset('');
    this.filterForm.controls['ot_odoo'].reset(null);
    this.filterForm.controls['nombre_cliente'].reset('');
    this.filterForm.controls['rut_cliente'].reset('');
    this.filterForm.controls['estado'].reset('Todos los estados');
  }

  // ---- Órdenar por ---- /
  /** Para ordenar las ordenes por fecha ASC o DSC en la tabla */
  toggleSortOrderDate() {
    this.isAscending = !this.isAscending;
    this.sortWorkOrdersDate();
  }
  sortWorkOrdersDate() {
    const sortedOrders = this.workOrders;

    sortedOrders.sort((a, b) => {
      const dateASC = this.parseDate(a.fecha_creacion ?? '');
      const dateDSC = this.parseDate(b.fecha_creacion ?? '');

      if (this.isAscending) {
        // Ordenar ASC
        return dateASC.getTime() - dateDSC.getTime();
      } else {
        // Ordenar DSC
        return dateDSC.getTime() - dateASC.getTime();
      }
    });

    this.workOrders = sortedOrders;
  }
  /** Para parsear las fechas y obtener el tiempo exacto */
  parseDate(dateString: string): Date {
    // Split the date and time components
    const [datePart, timePart] = dateString.split(' - ');

    // Extract the year, month, and day from the date component
    const [year, month, day] = datePart.split('-').map(Number);

    // Extract the hours and minutes from the time component
    const [time, period] = timePart.split(' ');
    let [hours, minutes] = time.split(':').map(Number);

    // Convert hours to 24-hour format if the period is PM
    if (period == 'PM' && hours !== 12) {
      hours += 12;
    } else if (period == 'AM' && hours == 12) {
      hours = 0;
    }

    minutes = minutes ?? 0;

    // Create a new Date object with the extracted components
    return new Date(year, month - 1, day, hours, minutes);
  }
  /**Para ordenar las ordenes por OT asc o dsc */
  toggleSortOrderOT() {
    this.isAscending = !this.isAscending;
    this.sortWorkOrdersOT();
  }
  sortWorkOrdersOT() {
    const sortedOrders = this.workOrders;
    // Quitar 2 primeros caracteres de la OT "OT"
    sortedOrders.sort((a: FrontendWorkOrder, b: FrontendWorkOrder) => {
      const otA = a.SK?.substring(2);
      const otB = b.SK?.substring(2);

      // Ordenar ASC o DSC
      if (this.isAscending) {
        return Number(otA) - Number(otB);
      } else {
        return Number(otB) - Number(otA);
      }
    });

    this.workOrders = sortedOrders;
  }

  // ---- Selección múltiple y singular ---- /
  /** Seleccionar todas las ordenes */
  selectAll(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    if (checked) {
      this.workOrders.forEach((order) => this.selectedOrders.add(order));
    } else {
      this.selectedOrders.clear();
    }
    return this.selectedOrders;
  }
  /** Seleccionar una orden particular */
  selectSingle(order: FrontendWorkOrder, event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    const clicked = event.type == 'click';
    if (checked || clicked) {
      this.selectedOrders.add(order);
      if (this.selectedOrders.size == this.workOrders.length) {
        this.selectAllFlag = true;
      }
    } else {
      this.selectedOrders.delete(order);
      this.selectAllFlag = false;
    }

    return this.selectedOrders;
  }

  // ---- Acciones de las ordenes ---- /
  /** Botón "Enviar Pedido": Enviar ordenes seleccionadas */
  sendOrders(odoo_order_number?: number) {
    const ordersToSend: FrontendWorkOrder[] = []; // Paso 1: Crear un array para almacenar las órdenes seleccionadas
    const workOrders = {
      workOrders: ordersToSend,
    };
    // Aquí se envía el estado "Confirmado" y se modifica la orden.
    for (const order of this.selectedOrders) {
      const updatedOrder: FrontendWorkOrder = {
        ...order,
        estado: 'Confirmado',
      };

      const updatedOrderInOdoo: FrontendWorkOrder = {
        ...order,
        odoo_id: odoo_order_number ?? 0,
        estado: 'Confirmado',
      };

      ordersToSend.push(odoo_order_number ? updatedOrderInOdoo : updatedOrder);
    }
    console.log(workOrders);
    this.workOrderSvC.insertOTinOdoo(workOrders).subscribe({
      next: (res) => {
        this.getAllOrders();
        this.selectedOrders.clear();
        this.openModal('success');
        return res;
      },
      error: (error) => {
        this.openModal('error');

        return error;
      },
    });
  }
  /** Botón "Realizado" permite cambiar el estado de la orden a Realizado */
  doneOrder() {
    // Aquí se envía el estado "Realizado" y se modifica la orden.
    for (const order of this.selectedOrders) {
      const updatedOrder: FrontendWorkOrder = {
        ...order,
        estado: 'Realizado',
      };
      console.log(updatedOrder);

      if (this.checkOrdersState() == 'Confirmado') {
        this.workOrderSvC
          .updateOT(order.PK, order.SK, JSON.stringify(updatedOrder))
          .subscribe({
            next: (res) => {
              this.getAllOrders();
              this.selectedOrders.clear();
              this.openModal('success');
              return res;
            },
            error: (error) => {
              this.openModal('error');

              return error;
            },
          });
      } else {
        this.openModal('orderWasRealized', this.selectedOrders);
      }
    }
  }
  /**Añadir a pedido existente */
  addToOdooExistentOrder() {
    //Obtenemos el formControl del input del número de orden de odoo
    const odoo_number_control =
      this.modalComponent.form.controls['odoo_order_number'];
    //Lo hacemos campo obligatorio
    odoo_number_control.setValidators([Validators.required]);
    odoo_number_control.updateValueAndValidity();
    //Almacenamos su valor en la variable odoo_order_number
    this.odoo_order_number = odoo_number_control.value;

    if (odoo_number_control.valid) {
      this.sendOrders(this.odoo_order_number);
    }
  }
  /** Descargar PDF orden */
  downloadSinglePDF(
    PK: FrontendWorkOrder['PK'],
    SK: FrontendWorkOrder['SK'],
    printType: 'xtension' | 'client'
  ) {
    this.printSvC.printPDF(PK, SK, printType).subscribe((res) => {
      const blob = new Blob([res], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = `${SK}.pdf`; // Aquí puedes poner el nombre que quieras
      link.click();
    });
  }
  /** Descargar PDF multiples ordenes */
  downloadMultiplePDF(printType: 'xtension' | 'client') {
    for (const order of this.selectedOrders) {
      const orderSelected = this.workOrders.find(
        (orderSelected) => orderSelected == order
      );
      this.printSvC
        .printPDF(orderSelected?.PK, orderSelected?.SK, printType)
        .subscribe((res) => {
          const blob = new Blob([res], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = `${orderSelected?.SK}.pdf`; // Aquí puedes poner el nombre que quieras
          link.click();
        });
    }
  }
  //Exportar a excel
  exportToExcel(): void {
    if (this.selectedOrders.size > 0) {
      // Convertir el Set a un array y luego utilizar map
      this.exportData = Array.from(this.selectedOrders).map((order) => ({
        Fecha: order.fecha_creacion,
        Cliente: order.header.clienteB2B.data.nombre_fantasia,
        'OT Xtension': order.SK,
        'OT Cliente': order.header.orden_interna,
        'OT Odoo': order.odoo_id,
        Foco: order.body.detalle_prescripcion.foco,
        Prescripción: order.body.detalle_prescripcion.prescripcion
          .map((p) => {
            return `Sph: ${p.rightEye.sph ?? 0}, Cyl: ${
              p.rightEye.cyl ?? 0
            }, Add: ${p.rightEye.add ?? 0} | Sph: ${p.leftEye.sph ?? 0}, Cyl: ${
              p.leftEye.cyl ?? 0
            }, Add: ${p.leftEye.add ?? 0}`;
          })
          .join(' | '),
        //iterar sobre eyeglasses y mostrar material, armado y tratamiento
        Trabajo: order.body.trabajos
          ?.map((eyeglass, index) => {
            return `${index + 1}. ${eyeglass.type}: ${eyeglass.material}, ${
              eyeglass.assembly
            }, ${eyeglass.treatment}`;
          })
          .join(' | '),
        Observaciones: order.body.observaciones,
        Estado: order.estado,
      }));
    } else {
      //Exportar a excel
      this.exportData = this.originalWorkOrders.map((order) => ({
        Fecha: order.fecha_creacion,
        Cliente: order.header.clienteB2B.data.nombre_fantasia,
        'OT Xtension': order.SK,
        'OT Interna': order.header.orden_interna,
        Foco: order.body.detalle_prescripcion.foco,
        Prescripción: order.body.detalle_prescripcion.prescripcion
          .map((p) => {
            return `Sph: ${p.rightEye.sph ?? 0}, Cyl: ${
              p.rightEye.cyl ?? 0
            }, Add: ${p.rightEye.add ?? 0} | Sph: ${p.leftEye.sph ?? 0}, Cyl: ${
              p.leftEye.cyl ?? 0
            }, Add: ${p.leftEye.add ?? 0}`;
          })
          .join(' | '),
        //iterar sobre eyeglasses y mostrar material, armado y tratamiento
        Trabajo: order.body.trabajos
          ?.map((eyeglass) => {
            return `${eyeglass.material}, ${eyeglass.assembly}, ${eyeglass.treatment}`;
          })
          .join(' | '),
        Estado: order.estado,
      }));
    }

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.exportData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const date = this.today.toFormat('dd-MM-yyyy');
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `Informe Operativo ${date}.xlsx`);
  }

  // ---- Modal ---- /
  /** Apertura del modal y su mensaje según el tipo de modal */
  openModal(
    modalType: ModalType['type'],
    ordersSelected?: Set<FrontendWorkOrder> | FrontendWorkOrder
  ) {
    this.mensajeModal = '';
    const order: FrontendWorkOrder = ordersSelected as FrontendWorkOrder;
    switch (modalType) {
      case 'sendOrder':
        //Si solo hay una orden seleccionada, más de una o ninguna
        if (this.selectedOrders.size == 1) {
          this.mensajeModal = `¿Desea crear un pedido nuevo en Odoo?`;
          this.botonesModal = [
            'Crear pedido nuevo',
            'Añadir a pedido existente',
          ];
          this.modalType = 'sendOrder';
        } else if (this.selectedOrders.size > 1) {
          this.mensajeModal = `¿Desea crear un pedido nuevo en Odoo con las ordenes seleccionadas?`;
          this.botonesModal = [
            'Crear pedido nuevo',
            'Añadir a pedido existente',
          ];
          this.modalType = 'sendOrder';
        } else {
          this.openModal('noOrdersSelected');
        }
        break;
      case 'doneOrders':
        //Si solo hay una orden seleccionada, más de una o ninguna
        if (this.selectedOrders.size == 1) {
          this.mensajeModal = `¿Desea marcar la orden como "Realizada"?`;
          this.botonesModal = ['Realizado', 'Cancelar'];
          this.modalType = 'doneOrders';
        } else if (this.selectedOrders.size > 1) {
          this.mensajeModal = `¿Desea marcar las ordenes como "Realizadas"?`;
          this.botonesModal = ['Realizado', 'Cancelar'];
          this.modalType = 'doneOrders';
        } else {
          this.openModal('noOrdersSelected');
        }
        break;
      case 'addToOdooExistentOrder':
        this.mensajeModal = 'Ingrese un número de orden existente en Odoo';
        this.botonesModal = ['Enviar', 'Cancelar'];
        this.modalType = 'addToOdooExistentOrder';
        break;
      case 'success':
        this.mensajeModal = 'Operación exitosa';
        this.botonesModal = ['Aceptar'];
        this.modalType = 'success';
        break;
      case 'error':
        this.mensajeModal = 'Error al realizar la operación, intente de nuevo';
        this.botonesModal = ['Aceptar'];
        this.modalType = 'error';
        break;
      case 'noOrdersSelected':
        this.mensajeModal = 'No hay ordenes seleccionadas';
        this.botonesModal = ['Aceptar'];
        this.modalType = 'noOrdersSelected';
        break;
      case 'orderWasSended':
        if (ordersSelected instanceof Set && ordersSelected.size > 1) {
          this.mensajeModal = `Las órdenes seleccionadas ya fueron enviadas o tienen estados distintos`;
          this.botonesModal = ['Aceptar'];
        } else {
          this.mensajeModal = `La orden seleccionada ya fue enviada o tiene un estado distinto`;
          this.botonesModal = ['Aceptar'];
        }
        this.modalType = 'orderWasSended';
        break;
      case 'orderWasRealized':
        if (ordersSelected instanceof Set && ordersSelected.size > 1) {
          this.mensajeModal = `Las órdenes seleccionadas ya fueron realizadas o tienen estados distintos`;
          this.botonesModal = ['Aceptar'];
        } else {
          this.mensajeModal = `La orden seleccionada ya fue realizada o tiene un estado distinto`;
          this.botonesModal = ['Aceptar'];
        }
        this.modalType = 'orderWasRealized';
        break;
      case 'noOrdersFound':
        this.mensajeModal = `No se encontraron órdenes con los filtros seleccionados`;
        this.botonesModal = ['Aceptar'];
        this.modalType = 'noOrdersFound';
        break;
      case 'odooOrderNotFound':
        this.mensajeModal =
          'Orden no encontrada, intente con un número de orden existente.';
        this.botonesModal = ['Aceptar'];
        this.modalType = 'error';
        break;
      case 'odooPartnerDoesntMatch':
        this.mensajeModal =
          'Más de una óptica seleccionada, seleccione ordenes que pertenezcan a un solo cliente.';
        this.botonesModal = ['Aceptar'];
        this.modalType = 'error';
        break;
      case 'viewOrder':
        this.viewOrder = order;
        this.botonesModal =
          this.viewOrder.estado == 'Enviado'
            ? ['Enviar a Odoo', 'Descargar', 'Añadir a pedido existente']
            : this.viewOrder.estado == 'Confirmado'
            ? ['Realizado', 'Descargar']
            : ['Descargar'];
        this.modalType = 'viewOrder';
        break;
      default:
        break;
    }
    this.modalOpen = true;
  }
  /** Selección de operaciones disponibles en el modal */
  selectOperation(accion: string) {
    switch (accion) {
      case 'Enviar':
        this.addToOdooExistentOrder();
        break;
      case 'Añadir a pedido existente':
        switch (this.modalType) {
          case 'sendOrder':
            this.openModal('addToOdooExistentOrder');
            break;
          case 'viewOrder':
            this.addToOdooExistentOrder();
            break;
          default:
            break;
        }
        break;
      case 'Enviar a Odoo':
        this.sendOrders();
        break;
      case 'Crear pedido nuevo':
        this.sendOrders();
        break;
      case 'Realizado':
        this.doneOrder();
        break;
      case 'Aceptar':
        this.closeModal();
        break;
      case 'Cancelar':
        this.closeModal();
        break;
      case 'Descargar':
        this.downloadSinglePDF(
          this.viewOrder.PK,
          this.viewOrder.SK,
          'xtension'
        );
        break;
      default:
        this.modalOpen = false;
        break;
    }
  }
  /** Cerrar modal */
  closeModal() {
    this.modalOpen = false;
    this.selectedOrders.clear();
  }

  // ---- Paginador ---- /
  // Función para obtener las órdenes de la página actual.
  getOrdersForPage(): FrontendWorkOrder[] {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    return this.workOrders.slice(startIndex, endIndex);
  }
  // Función para cambiar registros por página
  changeRecordsPerPage() {
    this.pageSize = this.recordsPerPage;
    this.currentPage = 1; // Opcional: restablecer a la primera página
    this.getOrdersForPage();
  }
  // Función para ir a una página específica.
  goToPage(page: number): void {
    if (page >= 1 && page <= this.getTotalPages()) {
      this.currentPage = page;
    }
  }
  // Función para ir a la página siguiente.
  nextPage(): void {
    if (this.currentPage < this.getTotalPages()) {
      this.currentPage++;
    }
  }
  // Función para ir a la página anterior.
  prevPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }
  // Función para obtener el número total de páginas.
  getTotalPages(): number {
    return Math.ceil(this.workOrders.length / this.pageSize);
  }

  // ---- helpers ---- /
  // ---- Comprobar estados de las ordenes seleccionadas para habilitar botones de selección masiva ---- /
  /** Comprobar si las ordenes seleccionadas tienen el mismo estado */
  checkOrdersState(): string {
    const ordersArray = Array.from(this.selectedOrders);
    const ordersState = ordersArray.map((order) => order.estado);
    const uniqueStates = new Set(ordersState);
    return uniqueStates.size == 1 ? Array.from(uniqueStates)[0] : '';
  }

  /** Formatear números de la tabla */
  numberFormatter(orderNumber: string, tenant: string) {
    return this.formaterSvC.numberFormatter(orderNumber, tenant);
  }
}
