import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export async function getTypeOfFormGroup(type: string): Promise<FormGroup> {
  // Values default lenses form
  const materialDefault = 'Orgánico 1.56';
  const treatmentDefault = 'Filtro Azúl';
  const assemblyDefault = 'Simple';
  const fb = new FormBuilder();

  let newFormGroup: FormGroup;

  switch (type) {
    case 'Cerca':
      newFormGroup = fb.group({
        type: 'Cerca',
        treatment: [treatmentDefault, [Validators.required]],
        material: [materialDefault, [Validators.required]],
        assembly: [assemblyDefault, [Validators.required]],
        frame: [''],
        tinted: [''],
      });
      break;
    case 'Bifocal':
      newFormGroup = fb.group({
        type: 'Bifocal',
        treatment: [treatmentDefault, [Validators.required]],
        material: [materialDefault, [Validators.required]],
        assembly: [assemblyDefault, [Validators.required]],
        frame: [''],
        tinted: [''],
      });
      break;
    case 'Multifocal':
      newFormGroup = fb.group({
        type: 'Multifocal',
        treatment: [treatmentDefault, [Validators.required]],
        material: [materialDefault, [Validators.required]],
        assembly: [assemblyDefault, [Validators.required]],
        frame: [''],
        tinted: [''],
      });
      break;
    default:
      newFormGroup = fb.group({
        type: 'Lejos',
        treatment: [treatmentDefault, [Validators.required]],
        material: [materialDefault, [Validators.required]],
        assembly: [assemblyDefault, [Validators.required]],
        frame: [''],
        tinted: [''],
      });
      break;
  }

  return newFormGroup;
}
