import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'x-main',
  templateUrl: './main.component.html',
})
export class MainComponent {
  constructor(public auth0: AuthService) {}

  login() {
    this.auth0.loginWithRedirect({
      appState: {
        target: '/home',
      },
    });
  }
}
