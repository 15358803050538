import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class OrderNumberFormaterService {
  numberFormatter(xtensionOrderNumber: string, tenant: string): string {
    const tenantFormated = tenant.replace(/^tenant/, '');

    return `${xtensionOrderNumber} / ${tenantFormated}`;
  }
}
