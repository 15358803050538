<x-header-title
  [title]="'Bandeja de entrada'"
  [subtitle]="'Aquí encontrará las ordenes ingresadas por los clientes.'"
></x-header-title>

<!--Reset filters-->
<xcontainer class="mb-2">
  <div class="w-full flex justify-end">
    <ul>
      <li
        class="hover:bg-[#eff0f2] px-2 duration-300 cursor-pointer rounded-full"
        (click)="resetFilters()"
      >
        <a class="underline text-sm text-xtitles">Refrescar</a>
      </li>
    </ul>
  </div>
</xcontainer>

<!--Filters Form-->
<xcontainer class="mb-4">
  <form
    [formGroup]="filterForm"
    class="w-full inline-flex justify-start space-x-3 items-center bg-[#F4F5F7] border p-4"
  >
    <!--Filtro por Estado-->
    <select
      formControlName="estado"
      class="w-48 border border-xlines text-xs rounded-sm p-2.5 bg-white text-xtitles font-normal ring-0 focus:ring-0 focus:ring-xblue focus:border-xblue cursor-pointer"
    >
      <option value="Todos los estados">Todos los estados</option>
      <option *ngFor="let state of states" [value]="state.state_name">
        {{ state.public_name }}
      </option>
    </select>
    <!--Búsqueda por OT-->
    <div class="inline-flex justify-between items-center relative">
      <input
        type="text"
        id="ot"
        formControlName="numero_ot"
        class="w-48 relative text-xtitles bg-white font-xs rounded-sm text-xs px-4 py-2.5 inline-flex items-center border border-xlines focus:ring-0 focus:ring-xblue focus:border-xblue placeholder:text-xplaceholders"
        placeholder="OT Xtension"
      />
    </div>
    <!--Búsqueda por OT odoo-->
    <div class="inline-flex justify-between items-center relative">
      <input
        type="number"
        id="ot_odoo"
        formControlName="ot_odoo"
        class="w-48 relative text-xtitles bg-white font-xs rounded-sm text-xs px-4 py-2.5 inline-flex items-center border border-xlines focus:ring-0 focus:ring-xblue focus:border-xblue placeholder:text-xplaceholders"
        placeholder="OT Odoo"
      />
    </div>

    <!--Búsqueda por Nombre Cliente-->
    <div class="inline-flex justify-between items-center relative">
      <input
        type="text"
        formControlName="nombre_cliente"
        name="nombre_cliente"
        class="w-48 relative text-xtitles bg-white font-xs rounded-sm text-xs px-4 py-2.5 inline-flex items-center border border-xlines placeholder:text-xplaceholders font-normal focus:ring-0 focus:ring-xblue focus:border-xblue"
        placeholder="Nombre Cliente"
      />
    </div>
    <!--Búsqueda por Rut Cliente-->
    <div class="inline-flex justify-between items-center relative">
      <input
        type="text"
        id="cliente"
        formControlName="rut_cliente"
        class="w-48 relative text-xtitles bg-white font-xs rounded-sm text-xs px-4 py-2.5 inline-flex items-center border border-xlines placeholder:text-xplaceholders font-normal focus:ring-0 focus:ring-xblue focus:border-xblue"
        placeholder="Rut óptica (11111111-1)"
      />
    </div>

    <div class="flex justify-end">
      <ul>
        <li
          class="hover:bg-[#eff0f2] px-2 duration-300 cursor-pointer rounded-full"
          (click)="resetFilters()"
        >
          <a class="underline text-xs text-xtitles">Limpiar Filtros</a>
        </li>
      </ul>
    </div>
  </form>
</xcontainer>

<!--Buttons-->
<xcontainer class="mt-6 mb-4">
  <div class="inline-flex justify-start items-center w-full space-x-3">
    <!--Export to Excel Button-->
    <primary-button
      type="submit"
      (click)="exportToExcel()"
      [ngClass]="[
        'bg-xwhite text-black border border-black  duration-300 hover:bg-slate-100'
      ]"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20px"
        height="20px"
        viewBox="0 0 32 32"
      >
        <defs>
          <linearGradient
            id="IconifyId18ecabe6ed66248cc470"
            x1="4.494"
            x2="13.832"
            y1="-2092.086"
            y2="-2075.914"
            gradientTransform="translate(0 2100)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#18884f" />
            <stop offset=".5" stopColor="#117e43" />
            <stop offset="1" stopColor="#0b6631" />
          </linearGradient>
        </defs>
        <path
          fill="#185c37"
          d="M19.581 15.35L8.512 13.4v14.409A1.192 1.192 0 0 0 9.705 29h19.1A1.192 1.192 0 0 0 30 27.809V22.5Z"
        />
        <path
          fill="#21a366"
          d="M19.581 3H9.705a1.192 1.192 0 0 0-1.193 1.191V9.5L19.581 16l5.861 1.95L30 16V9.5Z"
        />
        <path fill="#107c41" d="M8.512 9.5h11.069V16H8.512Z" />
        <path
          d="M16.434 8.2H8.512v16.25h7.922a1.2 1.2 0 0 0 1.194-1.191V9.391A1.2 1.2 0 0 0 16.434 8.2"
          opacity=".1"
        />
        <path
          d="M15.783 8.85H8.512V25.1h7.271a1.2 1.2 0 0 0 1.194-1.191V10.041a1.2 1.2 0 0 0-1.194-1.191"
          opacity=".2"
        />
        <path
          d="M15.783 8.85H8.512V23.8h7.271a1.2 1.2 0 0 0 1.194-1.191V10.041a1.2 1.2 0 0 0-1.194-1.191"
          opacity=".2"
        />
        <path
          d="M15.132 8.85h-6.62V23.8h6.62a1.2 1.2 0 0 0 1.194-1.191V10.041a1.2 1.2 0 0 0-1.194-1.191"
          opacity=".2"
        />
        <path
          fill="url(#IconifyId18ecabe6ed66248cc470)"
          d="M3.194 8.85h11.938a1.193 1.193 0 0 1 1.194 1.191v11.918a1.193 1.193 0 0 1-1.194 1.191H3.194A1.192 1.192 0 0 1 2 21.959V10.041A1.192 1.192 0 0 1 3.194 8.85"
        />
        <path
          fill="#fff"
          d="m5.7 19.873l2.511-3.884l-2.3-3.862h1.847L9.013 14.6c.116.234.2.408.238.524h.017c.082-.188.169-.369.26-.546l1.342-2.447h1.7l-2.359 3.84l2.419 3.905h-1.809l-1.45-2.711A2.355 2.355 0 0 1 9.2 16.8h-.024a1.688 1.688 0 0 1-.168.351l-1.493 2.722Z"
        />
        <path
          fill="#33c481"
          d="M28.806 3h-9.225v6.5H30V4.191A1.192 1.192 0 0 0 28.806 3"
        />
        <path fill="#107c41" d="M19.581 16H30v6.5H19.581Z" />
      </svg>
      <span>
        {{ selectedOrders.size > 0 ? 'Exportar selección' : 'Exportar todo' }}
      </span></primary-button
    >
    <!--Download pdf button-->
    <primary-button
      type="submit"
      [disabled]="selectedOrders.size == 0"
      (click)="downloadMultiplePDF('xtension')"
      [ngClass]="[
        selectedOrders.size !== 0
          ? 'bg-xwhite text-black border border-black  hover:bg-slate-50'
          : 'bg-slate-100 text-xplaceholders'
      ]"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20px"
        height="20px"
        viewBox="0 0 32 32"
      >
        <path
          fill="#909090"
          d="m24.1 2.072l5.564 5.8v22.056H8.879V30h20.856V7.945z"
        />
        <path fill="#f4f4f4" d="M24.031 2H8.808v27.928h20.856V7.873z" />
        <path fill="#7a7b7c" d="M8.655 3.5h-6.39v6.827h20.1V3.5z" />
        <path fill="#dd2025" d="M22.472 10.211H2.395V3.379h20.077z" />
        <path
          fill="#464648"
          d="M9.052 4.534H7.745v4.8h1.028V7.715L9 7.728a2.042 2.042 0 0 0 .647-.117a1.427 1.427 0 0 0 .493-.291a1.224 1.224 0 0 0 .335-.454a2.13 2.13 0 0 0 .105-.908a2.237 2.237 0 0 0-.114-.644a1.173 1.173 0 0 0-.687-.65a2.149 2.149 0 0 0-.409-.104a2.232 2.232 0 0 0-.319-.026m-.189 2.294h-.089v-1.48h.193a.57.57 0 0 1 .459.181a.92.92 0 0 1 .183.558c0 .246 0 .469-.222.626a.942.942 0 0 1-.524.114m3.671-2.306c-.111 0-.219.008-.295.011L12 4.538h-.78v4.8h.918a2.677 2.677 0 0 0 1.028-.175a1.71 1.71 0 0 0 .68-.491a1.939 1.939 0 0 0 .373-.749a3.728 3.728 0 0 0 .114-.949a4.416 4.416 0 0 0-.087-1.127a1.777 1.777 0 0 0-.4-.733a1.63 1.63 0 0 0-.535-.4a2.413 2.413 0 0 0-.549-.178a1.282 1.282 0 0 0-.228-.017m-.182 3.937h-.1V5.392h.013a1.062 1.062 0 0 1 .6.107a1.2 1.2 0 0 1 .324.4a1.3 1.3 0 0 1 .142.526c.009.22 0 .4 0 .549a2.926 2.926 0 0 1-.033.513a1.756 1.756 0 0 1-.169.5a1.13 1.13 0 0 1-.363.36a.673.673 0 0 1-.416.106m5.08-3.915H15v4.8h1.028V7.434h1.3v-.892h-1.3V5.43h1.4v-.892"
        />
        <path
          fill="#dd2025"
          d="M21.781 20.255s3.188-.578 3.188.511s-1.975.646-3.188-.511m-2.357.083a7.543 7.543 0 0 0-1.473.489l.4-.9c.4-.9.815-2.127.815-2.127a14.216 14.216 0 0 0 1.658 2.252a13.033 13.033 0 0 0-1.4.288Zm-1.262-6.5c0-.949.307-1.208.546-1.208s.508.115.517.939a10.787 10.787 0 0 1-.517 2.434a4.426 4.426 0 0 1-.547-2.162Zm-4.649 10.516c-.978-.585 2.051-2.386 2.6-2.444c-.003.001-1.576 3.056-2.6 2.444M25.9 20.895c-.01-.1-.1-1.207-2.07-1.16a14.228 14.228 0 0 0-2.453.173a12.542 12.542 0 0 1-2.012-2.655a11.76 11.76 0 0 0 .623-3.1c-.029-1.2-.316-1.888-1.236-1.878s-1.054.815-.933 2.013a9.309 9.309 0 0 0 .665 2.338s-.425 1.323-.987 2.639s-.946 2.006-.946 2.006a9.622 9.622 0 0 0-2.725 1.4c-.824.767-1.159 1.356-.725 1.945c.374.508 1.683.623 2.853-.91a22.549 22.549 0 0 0 1.7-2.492s1.784-.489 2.339-.623s1.226-.24 1.226-.24s1.629 1.639 3.2 1.581s1.495-.939 1.485-1.035"
        />
        <path fill="#909090" d="M23.954 2.077V7.95h5.633z" />
        <path fill="#f4f4f4" d="M24.031 2v5.873h5.633z" />
        <path
          fill="#fff"
          d="M8.975 4.457H7.668v4.8H8.7V7.639l.228.013a2.042 2.042 0 0 0 .647-.117a1.428 1.428 0 0 0 .493-.291a1.224 1.224 0 0 0 .332-.454a2.13 2.13 0 0 0 .105-.908a2.237 2.237 0 0 0-.114-.644a1.173 1.173 0 0 0-.687-.65a2.149 2.149 0 0 0-.411-.105a2.232 2.232 0 0 0-.319-.026m-.189 2.294h-.089v-1.48h.194a.57.57 0 0 1 .459.181a.92.92 0 0 1 .183.558c0 .246 0 .469-.222.626a.942.942 0 0 1-.524.114m3.67-2.306c-.111 0-.219.008-.295.011l-.235.006h-.78v4.8h.918a2.677 2.677 0 0 0 1.028-.175a1.71 1.71 0 0 0 .68-.491a1.939 1.939 0 0 0 .373-.749a3.728 3.728 0 0 0 .114-.949a4.416 4.416 0 0 0-.087-1.127a1.777 1.777 0 0 0-.4-.733a1.63 1.63 0 0 0-.535-.4a2.413 2.413 0 0 0-.549-.178a1.282 1.282 0 0 0-.228-.017m-.182 3.937h-.1V5.315h.013a1.062 1.062 0 0 1 .6.107a1.2 1.2 0 0 1 .324.4a1.3 1.3 0 0 1 .142.526c.009.22 0 .4 0 .549a2.926 2.926 0 0 1-.033.513a1.756 1.756 0 0 1-.169.5a1.13 1.13 0 0 1-.363.36a.673.673 0 0 1-.416.106m5.077-3.915h-2.43v4.8h1.028V7.357h1.3v-.892h-1.3V5.353h1.4v-.892"
        />
      </svg>
      <span>Descargar</span></primary-button
    >
  </div>
  <div class="inline-flex justify-end items-center w-full space-x-2">
    <ul class="flex flex-row">
      <li class="inline-flex text-xs items-center justify-center">
        <span class="inline-flex items-center sm:ml-2 font-semibold">
          Ver
          <div class="text-xblue font-semibold mx-2">
            <select
              [(ngModel)]="recordsPerPage"
              (change)="changeRecordsPerPage()"
              class="w-28 text-xs rounded-full px-2.5 bg-white text-xtitles font-normal ring-0 focus:ring-0 border-0 cursor-pointer hover:bg-[#eff0f2] duration-300"
            >
              <option value="10">10 registros</option>
              <option value="25">25 registros</option>
              <option value="50">50 registros</option>
            </select>
          </div>
        </span>
      </li>
    </ul>
  </div>
</xcontainer>

<!--Table-->
<xcontainer class="mb-10">
  <table class="border w-full shadow-lg">
    <tr class="bg-[#F4F5F7] border-b">
      <!--Select orders-->
      <th class="p-2 border-r text-xs font-semibold text-xtitles w-fit">
        <input
          id="default-checkbox"
          type="checkbox"
          [checked]="selectedOrders.size == workOrders.length"
          (change)="selectAll($event)"
          class="w-4 cursor-pointer h-4 text-xblue bg-gray-100 border-gray-300 rounded focus:ring-xblue ring-offset-gray-800 focus:ring-2"
        />
      </th>
      <!--Fecha-->
      <th class="p-2 border-r text-xs font-semibold text-xtitles w-fit">
        <div class="flex items-center justify-center">
          Fecha
          <a (click)="toggleSortOrderDate()" class="cursor-pointer"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-3 h-3 ml-1"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 320 512"
            >
              <path
                d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z"
              />
            </svg>
          </a>
        </div>
      </th>

      <!--Cliente-->
      <th class="border-r text-xs font-semibold text-xtitles w-fit">
        <div class="flex items-center justify-center">Cliente</div>
      </th>
      <!--Número de OT-->
      <th class="border-r text-xs font-semibold text-xtitles w-fit">
        <div class="flex items-center justify-center">
          OT Xtension
          <a (click)="toggleSortOrderOT()" class="cursor-pointer"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-3 h-3 ml-1"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 320 512"
            >
              <path
                d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z"
              />
            </svg>
          </a>
        </div>
      </th>
      <!--Orden interna-->
      <th class="border-r text-xs font-semibold text-xtitles w-fit">
        <div class="flex items-center justify-center">OT Interna</div>
      </th>
      <!--Orden ODOO-->
      <th class="border-r text-xs font-semibold text-xtitles w-fit">
        <div class="flex items-center justify-center">OT Odoo</div>
      </th>
      <!--Prescripción-->
      <th class="border-r text-xs font-semibold text-xtitles w-60">
        <div class="flex items-center justify-center">Prescripción</div>
      </th>
      <!--Trabajo-->
      <th class="border-r text-xs font-semibold text-xtitles w-72">
        <div class="flex items-center justify-center">Trabajo</div>
      </th>
      <th class="border-r text-xs font-semibold text-xtitles w-fit">
        <div class="flex items-center justify-center">Estado</div>
      </th>
      <th class="border-r text-xs font-semibold text-xtitles w-fit">
        <div class="flex items-center justify-center">Acciones</div>
      </th>
    </tr>
    <!--Todas las ordenes-->
    <tr
      class="OD bg-gray-50 border text-center duration-300 fade-in"
      *ngFor="let order of getOrdersForPage()"
    >
      <!--Select single order-->
      <td class="py-4 border-r text-xs font-semibold text-xtitles">
        <input
          id="{{ order.SK }}"
          type="checkbox"
          [value]="order"
          [checked]="selectedOrders.has(order)"
          (change)="selectSingle(order, $event)"
          class="w-4 h-4 text-xblue bg-gray-100 border-gray-300 rounded focus:ring-xblue ring-offset-gray-800 focus:ring-2 cursor-pointer"
        />
      </td>
      <!--Fecha-->
      <td class="border-r text-[11px]">
        {{ dateSvC.dateFormater(2, order.fecha_creacion) }}
      </td>
      <!--Cliente-->
      <td class="border-r text-[11px]">
        <span>
          {{ order.header.clienteB2B.data.nombre_fantasia }}
        </span>
      </td>
      <!--Orden Xtension-->
      <td class="border-r text-[11px]">
        {{ numberFormatter(order.SK ?? '', order.header.clienteB2B.SK) }}
      </td>
      <!--Orden Interna-->
      <td class="border-r text-[11px]">
        {{ order.header.orden_interna }}
      </td>
      <!--Orden ODOO -->
      <td class="border-r text-[11px]">
        {{ order.odoo_id ? order.odoo_id : '' }}
      </td>
      <!--Prescripción-->
      <td
        class="border-r inline-flex w-full space-x-14 text-left text-[10px] p-3"
      >
        <div
          *ngFor="
            let prescription of order.body.detalle_prescripcion.prescripcion;
            let i = index
          "
        >
          <p
            *ngIf="ordersData[order.SK ?? ''] && ordersData[order.SK ?? ''][i]"
          >
            <b>OD: </b>
            {{
              ordersData[order.SK ?? ''][i].OD_sph
                ? ordersData[order.SK ?? ''][i].OD_sph
                : ''
            }}
            /
            {{
              ordersData[order.SK ?? ''][i].OD_cyl
                ? ordersData[order.SK ?? ''][i].OD_cyl
                : ''
            }}

            {{
              ordersData[order.SK ?? ''][i].OD_add
                ? '/ Add: ' + ordersData[order.SK ?? ''][i].OD_add
                : ''
            }}
          </p>
          <p
            *ngIf="ordersData[order.SK ?? ''] && ordersData[order.SK ?? ''][i]"
          >
            <b>OI: </b>
            {{
              ordersData[order.SK ?? ''][i].OI_sph
                ? ordersData[order.SK ?? ''][i].OI_sph
                : ''
            }}
            /
            {{
              ordersData[order.SK ?? ''][i].OI_cyl
                ? ordersData[order.SK ?? ''][i].OI_cyl
                : ''
            }}

            {{
              ordersData[order.SK ?? ''][i].OI_add
                ? '/ Add: ' + ordersData[order.SK ?? ''][i].OI_add
                : ''
            }}
          </p>
        </div>
      </td>

      <!--Trabajo-->
      <td class="border-r flex-wrap text-left text-[10px] px-3">
        <p *ngFor="let eyeglass of order.body.trabajos; let i = index">
          {{
            getLensesValue(
              i,
              eyeglass.type,
              eyeglass.material,
              eyeglass.treatment
            )
          }}
        </p>
      </td>
      <!--Estado-->
      <td class="border-r text-[11px]">
        <span
          *ngIf="order.estado == 'Guardado'"
          class="text-[10px] px-2 py-1.5 rounded-full bg-yellow-200 text-yellow-700 shadow-md"
        >
          Guardado
        </span>
        <span
          *ngIf="order.estado == 'Enviado'"
          class="text-[10px] px-2 py-1.5 rounded-full bg-violet-200 text-violet-900 shadow-md"
        >
          Enviado
        </span>
        <span
          *ngIf="order.estado == 'Confirmado'"
          class="text-[10px] px-2 py-1.5 rounded-full bg-xblue text-white shadow-md"
        >
          En Odoo
        </span>
        <span
          *ngIf="order.estado == 'Realizado'"
          class="text-[10px] px-2 py-1.5 rounded-full bg-xgreen text-white shadow-md"
        >
          Realizado
        </span>
      </td>
      <!--Acciones-->
      <td class="border-r">
        <div class="inline-flex justify-between space-x-4 mt-1">
          <div class="space-x-3 space-y-3">
            <div
              class="text-xs relative before:z-10 before:absolute before:left-1/2 before:-top-3 before:w-max before:max-w-xs before:-translate-x-1/2 before:-translate-y-full before:rounded-lg before:bg-gray-700 before:px-2 before:py-1.5 before:text-white before:invisible before:content-[attr(data-tip)] after:z-10 after:absolute after:left-1/2 after:-top-3 after:h-0 after:w-0 after:-translate-x-1/2 after:border-8 after:border-t-gray-700 after:border-l-transparent after:border-b-transparent after:border-r-transparent after:invisible hover:before:visible hover:after:visible"
              data-tip="Ver"
            >
              <a class="text-xblue cursor-pointer duration-300">
                <button
                  (click)="
                    openModal('viewOrder', order); selectSingle(order, $event)
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-search"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                </button>
              </a>
            </div>
          </div>
        </div>
      </td>
    </tr>
  </table>
</xcontainer>

<!--Paginator-->
<xcontainer *ngIf="workOrders" class="mb-10">
  <div class="bg-white shadow-md w-full rounded-sm select-none cursor-default">
    <div class="flex items-center justify-between px-2 py-4 mx-auto">
      <ul class="flex flex-row">
        <li class="inline-flex text-xs items-center justify-center">
          <span class="inline-flex items-center sm:ml-2 font-semibold">
            Ver
            <div class="text-xblue font-semibold mx-2">
              <select
                [(ngModel)]="recordsPerPage"
                (change)="changeRecordsPerPage()"
                class="w-28 text-xs rounded-full px-2.5 bg-white text-xtitles font-normal ring-0 focus:ring-0 border-0 cursor-pointer hover:bg-[#eff0f2] duration-300"
              >
                <option value="10">10 registros</option>
                <option value="25">25 registros</option>
                <option value="50">50 registros</option>
              </select>
            </div>
          </span>
        </li>
      </ul>
      <ul class="flex flex-row">
        <li class="inline-flex text-xs items-center justify-between">
          <div
            class="cursor-pointer rounded-full hover:bg-slate-100 duration-300 p-1"
            (click)="prevPage()"
          >
            <span class="text-xblue">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-arrow-left"
              >
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </svg>
            </span>
          </div>
          <div class="sm:inline-flex font-semibold space-x-1">
            <span class="text-xblue">{{ currentPage }} </span>
            <span> de {{ getTotalPages() }} </span>
          </div>

          <div
            class="cursor-pointer rounded-full hover:bg-slate-100 duration-300 p-1"
            (click)="nextPage()"
          >
            <span class="text-xblue">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-arrow-right"
              >
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
            </span>
          </div>
        </li>
      </ul>
      <ul class="flex flex-row">
        <li class="inline-flex text-xs">
          <span class="sm:inline-flex sm:ml-2 font-semibold"
            >Total:
            <div class="text-xblue font-semibold mx-2" *ngIf="workOrders">
              {{
                workOrders.length == 1
                  ? workOrders.length + ' registro'
                  : workOrders.length + ' registros'
              }}
            </div>
          </span>
        </li>
      </ul>
    </div>
  </div>
</xcontainer>

<!--Modal-->
<x-modal
  [isOpen]="modalOpen"
  (closeModal)="modalOpen = false; closeModal()"
  [order]="viewOrder"
  (accionRealizada)="selectOperation($event)"
  [mensaje]="mensajeModal"
  [botones]="botonesModal"
  [modalType]="modalType"
>
  <!-- Contenido del modal -->
  <svg
    aria-hidden="true"
    class="mx-auto mb-4 text-xblue w-14 h-14"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    *ngIf="modalType !== 'success' && modalType !== 'error'"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    ></path>
  </svg>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-check-circle mx-auto mb-4 text-xgreen w-14 h-14"
    *ngIf="modalType == 'success'"
  >
    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
    <polyline points="22 4 12 14.01 9 11.01"></polyline>
  </svg>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-x-circle mx-auto mb-4 text-red-500 w-14 h-14"
    *ngIf="modalType == 'error'"
  >
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="15" y1="9" x2="9" y2="15"></line>
    <line x1="9" y1="9" x2="15" y2="15"></line>
  </svg>
</x-modal>
