/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

import { environment } from 'src/environments/environment';

import {
  catchError,
  map,
  Observable,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs';

interface UserRole {
  description: string;
  id: string;
  name: string;
}
@Injectable({
  providedIn: 'root',
})
export class Auth0ManagementService {
  private domain = environment.auth0API.domain;
  private apiTokenUrl = environment.auth0API.apiTokenUrl;
  private audience = environment.auth0API.audience;
  private clientId = environment.auth0API.clientId;
  private clientSecret = environment.auth0API.clientSecret;

  userData$: Observable<any> = this.getUserMetadata().pipe(shareReplay(1));
  token = '';

  constructor(private http: HttpClient, public auth0: AuthService) {}

  //post Token Auth0 Management User API
  getToken(): Observable<any> {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
    const body = {
      grant_type: 'client_credentials',
      client_id: this.clientId,
      client_secret: this.clientSecret,
      audience: this.audience,
    };

    return this.http
      .post<any>(this.apiTokenUrl, body, {
        headers: headers,
      })
      .pipe(
        map((res) => res.access_token), // Mapear la respuesta para obtener solo el token
        catchError((error) => {
          // Manejar errores
          console.log(error);
          return [];
        })
      );
  }

  //Obtener usuario Auth0
  getUser(userID: any): Observable<any> {
    // Obtener el usuario
    return this.getToken().pipe(
      catchError((error) => {
        // Manejar errores
        console.log(error);

        return [];
      }),
      // Procesar el token obtenido
      switchMap((token) => {
        // Configurar los encabezados con el token obtenido

        const options = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }),
        };

        // Realizar la solicitud HTTP con los encabezados configurados
        return this.http.get(`${this.domain}/api/v2/users/${userID}`, options);
      })
    );
  }

  //Obtener metadata de usuario Auth0
  getUserMetadata(): Observable<any> {
    return this.auth0.user$.pipe(
      take(1),
      map((user) => user?.sub), // Obtiene el ID del usuario logeado
      switchMap((userID) => {
        // Obtener el token de forma asíncrona
        return this.getToken().pipe(
          catchError((error) => {
            console.log(error);

            // Manejar errores al obtener el token (por ejemplo, redireccionar a página de inicio de sesión)
            return [];
          }),
          // Procesar el token obtenido
          tap((token) => {
            this.token = token;
          }),
          // Realizar la solicitud HTTP con los encabezados configurados
          switchMap(() => {
            const options = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.token}`,
              }),
            };
            return this.http.get(
              `${this.domain}/api/v2/users/${userID}`,
              options
            );
          }),
          shareReplay(1) // Compartir el resultado de la solicitud HTTP
        );
      })
    );
  }

  //Obtener rol de usuario Auth0
  getUserRoles(userID: any): Observable<UserRole[]> {
    // Obtener el usuario
    return this.getToken().pipe(
      catchError((error) => {
        // Manejar errores
        console.log(error);

        return [];
      }),
      // Procesar el token obtenido
      switchMap((token) => {
        // Configurar los encabezados con el token obtenido

        const options = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }),
        };

        // Realizar la solicitud HTTP con los encabezados configurados
        return this.http.get(
          `${this.domain}/api/v2/users/${userID}/roles`,
          options
        ) as Observable<UserRole[]>;
      })
    );
  }
}
