import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainComponent } from './shared/components/main/main.component';

import { AuthenticathedGuard } from './shared/guards/authenticathed.guard';
import { AuthGuard } from '@auth0/auth0-angular';

const routes: Routes = [
  { path: '', component: MainComponent, canActivate: [AuthenticathedGuard] },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'orders',
    loadChildren: () =>
      import('./orders/orders.module').then((m) => m.OrdersModule),
    canActivate: [AuthenticathedGuard],
    data: { requiredRole: 'clientB2B' },
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./reports/reports.module').then((m) => m.ReportsModule),
    canActivate: [AuthenticathedGuard],
    data: { requiredRole: 'clientB2B' },
  },
  {
    path: 'inbox',
    loadChildren: () =>
      import('./inbox/inbox.module').then((m) => m.InboxModule),
    canActivate: [AuthenticathedGuard],
    data: { requiredRole: 'xtension-user' },
  },
  /*
  {
    path: 'settings',
    loadChildren: () =>
      import('./settings/settings.module').then((m) => m.SettingsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then((m) => m.HelpModule),
    canActivate: [AuthGuard],
  },
  */
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
