export const environment = {
  production: false,
  apiUrl: 'https://dev-api.xtension.app',
  baseUrl: 'https://dev.xtension.app',
  auth0: {
    domain: 'dev-jzt52zllb4txocug.us.auth0.com',
    clientId: 'aFh1ZkeGqFUvAGwAYtyI1RAzMWAjbaqA',
    audience: 'https://gpi21i8d37.execute-api.us-east-1.amazonaws.com',
    httpInterceptor: {
      allowedList: [
        {
          uri: 'https://dev-api.xtension.app/*',
        },
      ],
    },
  },
  auth0API: {
    domain: 'https://dev-jzt52zllb4txocug.us.auth0.com',
    apiTokenUrl: 'https://dev-jzt52zllb4txocug.us.auth0.com/oauth/token',
    audience: 'https://dev-jzt52zllb4txocug.us.auth0.com/api/v2/',
    clientId: 'IkVMYBNkddKrYkEiZzEPIxUbFLBAGleb',
    clientSecret:
      'Ql2ytA8r36jXXkjh5Ve1a0PkrcSL-UV7ub4SiZeF5UDft_M1NsG-JYyvpKPDUcIP',
  },
};
