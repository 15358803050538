import { Component, Input } from '@angular/core';

@Component({
  selector: 'primary-button',
  template: `<button
    type="{{ type }}"
    [disabled]="disabled"
    [ngClass]="[
      'inline-flex justify-center items-center space-x-1 rounded-sm px-4 py-1 text-xs font-semibold leading-7 shadow-sm  transition duration-300 ease-in-out'
    ]"
  >
    <ng-content></ng-content>
  </button> `,
})
export class PrimaryButtonComponent {
  @Input() class = '';
  @Input() disabled!: any;
  @Input() type = '';
}
