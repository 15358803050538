<div class="inline-flex justify-between items-center relative">
  <input
    type="text"
    class="w-48 relative text-xtitles bg-white rounded-sm text-xs px-4 py-2.5 inline-flex items-center border border-xlines placeholder:text-xtitles font-normal focus:ring-0 focus:ring-xblue focus:border-xblue cursor-pointer"
    placeholder="Rango de fechas"
    (click)="togglePicker()"
    [value]="
      selectedRange
        ? formatDate(selectedRange.start) +
          ' - ' +
          formatDate(selectedRange.end)
        : ''
    "
    readonly
  />

  <div
    class="absolute w-48 text-xs top-0 left-0 mt-10 p-4 bg-white rounded shadow-lg"
    *ngIf="pickerVisible"
  >
    <div class="flex justify-between items-center">
      <button class="text-xtitles" (click)="changeMonth(-1)">&lt;</button>
      <div class="font-bold">{{ getFormattedCurrentDate() | uppercase }}</div>
      <button class="text-xtitles" (click)="changeMonth(1)">&gt;</button>
    </div>
    <div class="grid grid-cols-7 gap-1 mt-4">
      <div
        *ngFor="let day of daysInMonth; let i = index"
        [ngClass]="[
          'p-1 cursor-pointer rounded-sm flex items-center justify-center hover:text-white hover:bg-xblue duration-100',
          !isDaySelected(day) ? 'text-xtitles' : 'text-white bg-xblue'
        ]"
        (click)="selectDay(day)"
      >
        {{ day }}
      </div>
    </div>
    <div class="mt-4 flex justify-center items-center space-x-4">
      <primary-button
        type="submit"
        [ngClass]="['py-2 bg-xwhite text-black duration-300']"
        (click)="clearSelection()"
      >
        Limpiar
      </primary-button>
      <primary-button
        type="submit"
        [ngClass]="[
          !selectedRange
            ? 'bg-slate-100 text-xplaceholders'
            : 'bg-xblue text-white hover:bg-xblue-enfasis hover:duration-300'
        ]"
        (click)="applySelection()"
        [disabled]="!selectedRange"
      >
        Buscar
      </primary-button>
    </div>
  </div>
  <span
    class="absolute block right-2 top-3 select-none cursor-pointer text-xgreen"
  >
    <a (click)="togglePicker()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-calendar"
      >
        <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
        <line x1="16" y1="2" x2="16" y2="6"></line>
        <line x1="8" y1="2" x2="8" y2="6"></line>
        <line x1="3" y1="10" x2="21" y2="10"></line>
      </svg>
    </a>
  </span>
</div>
