import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';

import { registerLocaleData } from '@angular/common';
import localeEsCL from '@angular/common/locales/es-CL';

registerLocaleData(localeEsCL);
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';

//importaciones de terceros
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { SpinnerInterceptor } from './shared/interceptors/spinner.interceptor';
import { environment } from 'src/environments/environment';
import { MainModule } from './main/main.module';
import { ReportsModule } from './reports/reports.module';
import { InicioModule } from './inicio/inicio.module';
import { InboxModule } from './inbox/inbox.module';

@NgModule({
  declarations: [AppComponent],
  exports: [AuthModule],
  bootstrap: [AppComponent],
  imports: [
    AppRoutingModule, // Import the module into the application, with configuration
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      useRefreshTokens: true,
      useRefreshTokensFallback: true,
      cacheLocation: 'localstorage',
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: environment.auth0.audience,
        scope: 'openid profile email offline_access',
      },
      httpInterceptor: {
        allowedList: [
          {
            uri: environment.auth0.httpInterceptor.allowedList[0].uri,
          },
        ],
      },
    }),
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    InicioModule,
    InboxModule,
    MainModule,
    ReportsModule,
    SharedModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'es-CL' },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
