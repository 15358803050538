import { Component, Input } from '@angular/core';
export interface Question {
  name: string;
  description: string;
  category: string;
}
@Component({
  selector: 'x-accordion',
  templateUrl: './accordion.component.html',
})
export class AccordionComponent {
  @Input() items: Question[] = [];
  openIndex: number | null = null;

  toggleAccordion(index: number): void {
    if (this.openIndex === index) {
      this.openIndex = null;
    } else {
      this.openIndex = index;
    }
  }
}
