import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OperativeService {
  private api = env.apiUrl;

  constructor(private http: HttpClient) {}

  public getOperatives(PK: string): Observable<[]> {
    return this.http.get<[]>(`${this.api}/operative/${PK}`);
  }
}
