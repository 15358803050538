<nav
  class="relative flex flex-wrap items-center justify-between px-4 py-2 bg-white"
>
  <div class="container max-w-full flex flex-wrap items-center justify-between">
    <div
      class="w-full relative flex justify-between items-center lg:w-auto md:w-auto lg:static md:static lg:block md:block lg:justify-start md:justify-start"
    >
      <a class="inline-block" href="#pablo">
        <img
          src="../../../../assets/img/Full logo - BG light.PNG"
          alt="Icon"
          width="120"
          height="120"
        />
      </a>
      <button
        class="text-xtitles cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden md:hidden outline-none focus:outline-none"
        type="button"
        (click)="toggleNavbar()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-menu"
        >
          <line x1="3" y1="12" x2="21" y2="12"></line>
          <line x1="3" y1="6" x2="21" y2="6"></line>
          <line x1="3" y1="18" x2="21" y2="18"></line>
        </svg>
      </button>
    </div>
    <div
      [ngClass]="{ hidden: !showMenu, flex: showMenu }"
      class="flex lg:flex lg:flex-grow md:flex md:flex-grow justify-center items-center duration-300"
    >
      <ul
        class="flex items-center lg:space-x-4 md:space-x-4 sm:space-x-0 xs:space-x-0 lg:space-y-0 md:space-y-0 sm:space-y-2 xs:space-y-2 flex-col lg:flex-row md:flex-row list-none ml-auto"
      >
        <li class="nav-item w-full">
          <a
            class="py-2 flex space-x-2 items-end text-xs uppercase font-bold text-xsubtitles border-b-2 border-transparent hover:text-xtitles hover:border-xtitles hover:duration-300"
            [routerLink]="['']"
            routerLinkActive=" border-xtitles text-black"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-home"
            >
              <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
              <polyline points="9 22 9 12 15 12 15 22"></polyline>
            </svg>
            <span>Home</span>
          </a>
        </li>
        <li class="nav-item w-full">
          <a
            class="py-2 flex items-end text-xs uppercase font-bold text-xsubtitles border-b-2 border-transparent hover:text-xtitles hover:border-xtitles hover:duration-300"
            routerLink="#acerca"
            routerLinkActive="border-b-2 border-xtitles text-xblue"
          >
            <span>Acerca</span>
          </a>
        </li>
        <li class="nav-item w-full">
          <a
            class="py-2 flex items-end text-xs uppercase font-bold text-xsubtitles border-b-2 border-transparent hover:text-xtitles hover:border-xtitles hover:duration-300"
            routerLink="#contacto"
            routerLinkActive="border-b-2 border-xtitles text-xblue"
          >
            <span>Contacto</span>
          </a>
        </li>

        <primary-button
          type="submit"
          *ngIf="(auth0.isAuthenticated$ | async) === false"
          (click)="login()"
          [ngClass]="[
            'bg-xgreen text-white hover:bg-xgreen-enfasis  duration-300'
          ]"
          >Ingresar</primary-button
        >
      </ul>
    </div>
  </div>
</nav>
