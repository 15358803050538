import { Component, Input } from '@angular/core';
import { SalesOrder } from '../../interfaces/types/dynamo/salesOrder';
import { DateFormaterService } from '../../services/formater-services/date-formater.service';

@Component({
  selector: 'x-print-format',
  templateUrl: './print-format.component.html',
  styleUrls: ['./print-format.component.css'],
})
export class PrintFormatComponent {
  @Input() order!: SalesOrder;
  @Input() printType = 'clientB2B' || 'xtension_user';

  constructor(public dateSvC: DateFormaterService) {}
}
