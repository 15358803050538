import { Component, EventEmitter, Output } from '@angular/core';
import { DateTime } from 'luxon';

@Component({
  selector: 'x-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css'],
})
export class DatePickerComponent {
  pickerVisible = false;
  currentMonth: DateTime = DateTime.local();
  selectedRange: { start: DateTime | null; end: DateTime | null } | null = null;

  @Output() dateRange = new EventEmitter<string[]>();
  @Output() limpiarFiltro = new EventEmitter<boolean>();

  get daysInMonth() {
    return Array.from(
      { length: this.currentMonth.daysInMonth || 0 },
      (_, i) => i + 1
    );
  }
  getFormattedCurrentDate(): string {
    return this.currentMonth
      .setLocale('es')
      .toLocaleString({ month: 'long', year: 'numeric' });
  }

  togglePicker() {
    this.pickerVisible = !this.pickerVisible;
  }

  closePicker() {
    this.pickerVisible = false;
  }

  changeMonth(offset: number) {
    this.currentMonth = this.currentMonth
      .plus({ months: offset })
      .startOf('month');
  }

  selectDay(day: number) {
    if (
      !this.selectedRange ||
      (this.selectedRange.start && this.selectedRange.end)
    ) {
      this.selectedRange = {
        start: this.currentMonth.set({ day }),
        end: null,
      };
    } else if (!this.selectedRange.end) {
      const [start, end] = [
        this.selectedRange.start,
        this.currentMonth.set({ day }),
      ].sort();
      this.selectedRange = { start, end };
    }
  }

  isDaySelected(day: number): boolean {
    if (
      this.selectedRange &&
      this.selectedRange.start &&
      !this.selectedRange.end
    ) {
      return this.selectedRange.start.day === day;
    }

    if (
      this.selectedRange &&
      this.selectedRange.start &&
      this.selectedRange.end
    ) {
      const selectedDay = this.currentMonth.set({ day });
      return (
        selectedDay >= this.selectedRange.start &&
        selectedDay <= this.selectedRange.end
      );
    }

    return false;
  }

  applySelection() {
    if (
      this.selectedRange &&
      this.selectedRange.start &&
      this.selectedRange.end
    ) {
      const [start, end] = [
        this.selectedRange.start.toFormat('yyyy-MM-dd'),
        this.selectedRange.end.toFormat('yyyy-MM-dd'),
      ];
      this.dateRange.emit([start, end]);
      this.closePicker();
    }
  }

  clearSelection() {
    this.selectedRange = null;
    this.limpiarFiltro.emit(true);
    this.closePicker();
  }

  formatDate(date: DateTime | null) {
    return date ? date.toFormat('dd/MM/yyyy') : '';
  }
}
