import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { AuthService } from '@auth0/auth0-angular';
import { map, Observable, of, switchMap } from 'rxjs';
import { Auth0ManagementService } from '../services/auth0/auth0-management.service';
import { UserRoleAuth0 } from '../interfaces/types/auth0/user-role';

@Injectable({
  providedIn: 'root',
})

// Se implementa la interfaz `CanActivate` que controla si un usuario puede acceder a una ruta específica o no.
// Las dependencias necesarias para realizar la autenticación de usuario serán: `AuthService` que es el servicio raiz
// de Auth0, `Auth0ManagementService` que es un servicio creado por _Xtension_ para la lectura de la metadata del
// usuario logeado y finalmente `Router` que es un servicio de Angular para realizar redirecciones a rutas.
export class AuthenticathedGuard {
  constructor(
    private auth0: AuthService,
    private router: Router,
    private auth0Management: Auth0ManagementService
  ) {}
  //Donde será almacenado el rol del usuario logeado
  user_role: UserRoleAuth0['name'] = {} as UserRoleAuth0['name'];

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    // Se lee el rol requerido de la ruta a la que el usuario desea acceder
    const requiredRole = route.data['requiredRole'] as string;

    // Si la ruta tiene un rol requerido se lee el rol del usuario con la función getUserRoles()
    if (requiredRole) {
      return this.auth0.user$.pipe(
        switchMap((user) =>
          this.auth0Management.getUserRoles(user?.sub).pipe(
            map((user_role: UserRoleAuth0[]) => {
              this.user_role = user_role[0].name;

              // Se compara el user_role con el rol requerido por la ruta
              const hasRole = this.user_role === requiredRole;
              if (!hasRole) {
                // El usuario no tiene el rol requerido, redirigir a una página autorizada
                this.router.navigate(['/home']);
              }

              // Devuelve true si el usuario tiene el rol requerido y false en caso contrario
              return hasRole;
            })
          )
        )
      );
    }

    // Si no se requiere un rol, autoriza la ruta
    return of(true);
  }
}
