import { Component, OnInit } from '@angular/core';

import { AuthService, User } from '@auth0/auth0-angular';
import { UserRoleAuth0 } from 'src/app/shared/services/auth0/types/user-role';
import { Auth0ManagementService } from 'src/app/shared/services/auth0/auth0-management.service';

@Component({
  selector: 'x-menu-inicio',
  templateUrl: './menu.component.html',
  standalone: false,
})
export class MenuComponent {
  userAuth0 = {} as User | null | undefined;

  user_role: UserRoleAuth0 | null = {} as UserRoleAuth0;

  constructor(
    public auth0: AuthService,
    private auth0Management: Auth0ManagementService
  ) {}

  ngOnInit(): void {
    this.auth0.user$.subscribe((user) => {
      this.userAuth0 = user;
      this.auth0Management
        .getUserRoles(user?.sub)
        .subscribe((user_role: UserRoleAuth0[]) => {
          this.user_role = user_role[0];
        });
    });
  }
}
